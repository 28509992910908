import { connection, downloadFile } from "@/api/connection";
import { createBlobFromObject } from '@/helpers'

export default {
  getDepositContractClassifiers() {
    return connection.get("portal/deposit/classifiers");
  },
  postDepositContract({ files = [], documentCategoryId, contractData }) {
    if (!contractData?.customer?.document?.documentType) {
      contractData.customer.document.documentType = ''
    }
    if (!contractData?.customer?.document?.documentNumberition) {
      contractData.customer.document.documentNumber = ''
    }
    const formData = new FormData();
    formData.append(
      "contractData",
      createBlobFromObject(contractData)
    );
    if (files.length) {
      formData.append("documentCategoryId", documentCategoryId);
    }
    Array.from(files).forEach(file => {
      formData.append("files", file);
    });
    return connection.post("portal/deposit/contracts", formData);
  },
  getDepositContracts() {
    return connection.get("portal/deposit/contracts", {
      params: {
        size: 1000
      }
    });
  },
  getDepositContract({ id }) {
    return connection.get(`portal/deposit/contracts/${id}`);
  },
  getDepositContractDocuments({ id }) {
    return connection.get(`portal/deposit/contracts/${id}/documents`);
  },
  postDepositContractDocument({ id, files, comment = "", documentCategoryId }) {
    const formData = new FormData();
    formData.append("comment", comment);
    // if (files.length) {
    //   files.forEach(file => formData.append("file", file));
    //   formData.append("documentCategoryId", documentCategoryId);
    // }
    if (files) {
      formData.append("file", files[0]);
      formData.append("documentCategoryId", documentCategoryId);
    }
    return connection.post(
      `portal/deposit/contracts/${id}/documents`,
      formData
    );
  },
  getDepositContractPayments({ id }) {
    return connection.get(`portal/deposit/contracts/${id}/payments`);
  },
  postDepositContractSign({ id }) {
    return connection.post(`portal/deposit/contracts/${id}/sign`);
  },
  getDepositContractPreviewTemplate({ contractId, templateId, languageId }) {
    return downloadFile(
      `portal/deposit/contracts/${contractId}/templates/${templateId}/preview`,
      {
        params: {
          languageId
        }
      }
    );
  },
  getDepositContractAccountBalance: ({ id, accountType, date }) =>
    connection.get(
      `/portal/deposit/contracts/${id}/accounts/${accountType}/balance`,
      {
        params: {
          date
        }
      }
    )
};

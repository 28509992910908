import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import hwcrypto from "../../vendor/hwcrypto";
import { fromByteArray, toByteArray } from "base64-js";
import router from "@/router";

const { startLoading, endLoading } = createActionHelpers({
  moduleName: "loading"
});

export default {
  namespaced: true,

  state: {
    signData: null,
    signError: false,
    documentType: '',
    completedSessionStatus: ''
  },

  actions: {
    closeModal({ commit }) {
      commit("SET_SIGN_DATA");
      commit("SET_SIGN_ERROR", false);
    },
    async mobileIdSign({ state, commit, dispatch }, { documentId, signerData, documentType = 'loan' }) {

      commit("SET_SIGN_ERROR", false);
      commit("SET_DOCUMENT_TYPE", documentType)
      startLoading(dispatch, `signature:document:${documentId}:sign`);

      let response
      switch (documentType) {
        case 'loan':
          response = await api.putMobileIdSign({ documentId, signerData });
          break;
        case 'payout':
          response = await api.putMobileIdSignPayout({ documentId, signerData });
          break;
      }

      commit("SET_SIGN_DATA", response);

      setTimeout(() => {
        dispatch("mobileIdStatus", { newDocumentId: documentId, newDocumentType: documentType });
      }, 2000);
    },
    async mobileIdStatus({ state, commit, dispatch, rootState }, { newDocumentId , newDocumentType }) {
      if ((router.currentRoute.params.paymentId || newDocumentId) && newDocumentType === 'payout') {
        const {
          data: { sessionStatus, documentId, status, id }
        } = await api.getMobileIdSignStatusWithDocId({
          sessionCode: state.signData.sessionCode,
          documentId: router.currentRoute.params.paymentId || newDocumentId
        }).catch(( e ) => {
          if (e.status !== 404) {
            commit("SET_SIGN_ERROR", true); 
          } else {
            setTimeout(() => {
              dispatch("mobileIdStatus", { newDocumentId, newDocumentType });
            }, 2000);
          }
        });
        if (status === "WAITING_APPROVAL" || "PENDING") {
          if (status === "WAITING_APPROVAL" || "PENDING") {
            commit("SET_SIGN_DATA");
            commit("SET_STATUS", status)
            setTimeout(() => {
              dispatch(
                "payments/loadPayment",
                { paymentId: router.currentRoute.params.paymentId || newDocumentId, type: 'OUTGOING' },
                { root: true }
              );
            }, 3000);
          }
          switch (state.documentType) {
            case "loan":
              dispatch(
                  "loans/loadLoanResources",
                  { loanId: rootState.loans.loan.id },
                  { root: true }
              );
              break;
          }
          endLoading(dispatch, `signature:document:${id}:sign`);
        }
        endLoading(dispatch, `signature:document:${id}:sign`);
      } else {
        const {
          data: { sessionStatus, documentId }
        } = await api.getMobileIdSignStatus({
          sessionCode: state.signData.sessionCode
        }).catch(( e ) => { 
          if (e.status !== 404) {
            commit("SET_SIGN_ERROR", true); 
          } else {
            setTimeout(() => {
              dispatch("mobileIdStatus", { newDocumentId, newDocumentType });
            }, 2000);
          }
        });
        if (sessionStatus === "SIGNING_STARTED") {
          setTimeout(() => {
            dispatch("mobileIdStatus", { newDocumentId, newDocumentType });
          }, 2000);
        } else {
          if (sessionStatus === "SIGNING_FAILED") {
            commit("SET_SIGN_ERROR", true);
          }
          commit("SET_SIGN_DATA");
          commit("SET_STATUS", sessionStatus)
          switch (state.documentType) {
            case "loan":
              dispatch(
                  "loans/loadLoanResources",
                  { loanId: rootState.loans.loan.id },
                  { root: true }
              );
              break;
          }
          endLoading(dispatch, `signature:document:${documentId}:sign`);
        }
      }
    },
    async smartIdSign({ commit, dispatch }, { documentId, signerData, documentType = 'loan' }) {
      commit("SET_SIGN_ERROR", false);
      commit("SET_DOCUMENT_TYPE", documentType)
      startLoading(dispatch, `signature:document:${documentId}:sign`);

      let response
      switch (documentType) {
        case 'loan':
          response = await api.putSmartIdSign({ documentId, signerData });
          break;
        case 'payout':
          response = await api.putSmartIdSignPayout({ documentId, signerData });
          break;
      }

      commit("SET_SIGN_DATA", response);

      setTimeout(() => {
        dispatch("smartIdStatus", { newDocumentId: documentId, newDocumentType: documentType });
      }, 2000);

      return response.data;
    },
    async smartIdStatus({ state, commit, dispatch, rootState }, { newDocumentId , newDocumentType }) {
      if ((router.currentRoute.params.paymentId || newDocumentId) && newDocumentType === 'payout') {
        const {
          data: { sessionStatus, documentId, status, id }
        } = await api.getSmartIdSignStatusWithDocId({
          sessionCode: state.signData.sessionCode,
          documentId: router.currentRoute.params.paymentId || newDocumentId
        }).catch(( e ) => {
          if (e.status !== 404) {
            commit("SET_SIGN_ERROR", true); 
          } else {
            setTimeout(() => {
              dispatch("smartIdStatus", { newDocumentId, newDocumentType });
            }, 2000);
          }
        });
        if (status === "WAITING_APPROVAL" || "PENDING") {
          if (status === "WAITING_APPROVAL" || "PENDING") {
            commit("SET_SIGN_DATA");
            commit("SET_STATUS", status)
            setTimeout(() => {
              dispatch(
                "payments/loadPayment",
                { paymentId: router.currentRoute.params.paymentId || newDocumentId, type: 'OUTGOING' },
                { root: true }
              );
            }, 3000);
          }
          switch (state.documentType) {
            case "loan":
              dispatch(
                  "loans/loadLoanResources",
                  { loanId: rootState.loans.loan.id },
                  { root: true }
              );
              break;
          }
          endLoading(dispatch, `signature:document:${id}:sign`);
        }
        endLoading(dispatch, `signature:document:${id}:sign`);
      } else {
        const {
          data: { sessionStatus, documentId }
        } = await api.getSmartIdSignStatus({
          sessionCode: state.signData.sessionCode
        }).catch(( e ) => { 
          if (e.status !== 404) {
            commit("SET_SIGN_ERROR", true); 
          } else {
            setTimeout(() => {
              dispatch("smartIdStatus", { newDocumentId, newDocumentType });
            }, 2000);
          }
        });
        if (sessionStatus === "SIGNING_STARTED") {
          setTimeout(() => {
            dispatch("smartIdStatus", { newDocumentId, newDocumentType });
          }, 2000);
        } else {
          if (sessionStatus === "SIGNING_FAILED") {
            commit("SET_SIGN_ERROR", true);
          }
          commit("SET_SIGN_DATA");
          commit("SET_STATUS", sessionStatus)
          switch (state.documentType) {
            case "loan":
              dispatch(
                  "loans/loadLoanResources",
                  { loanId: rootState.loans.loan.id },
                  { root: true }
              );
              break;
          }
        }
        endLoading(dispatch, `signature:document:${documentId}:sign`);
      }
    },
    async idCardSign({ commit, dispatch, rootState }, { documentId, documentType = 'loan' }) {
      commit("SET_SIGN_ERROR", false);
      commit("SET_SIGN_DATA");
      startLoading(dispatch, `signature:document:${documentId}:sign`);

      try {
        const certificate = await hwcrypto.getCertificate({ lang: "en" });
        const hashRequest = { certificate: fromByteArray(certificate.encoded) };

        let response
        switch (documentType) {
          case 'loan':
            response = await api.getHashToSignWithIdCard({
              documentId,
              request: hashRequest
            });
            break;
          case 'payout':
            response = await api.getHashToSignWithIdCardPayout({
              documentId,
              request: hashRequest
            });
            break;
        }

        const hashResponse = response.data

        const signature = await hwcrypto.sign(
          certificate,
          {
            type: hashResponse.digestAlgorithm,
            value: toByteArray(hashResponse.digestToSign)
          },
          {}
        );
        let responseSignature

        switch (documentType) {
          case 'loan':
            responseSignature = await api.addIdCardSignature({
              sessionCode: hashResponse.sessionCode,
              request: { signature: fromByteArray(signature.value) }
            });
            break;
          case 'payout':
            responseSignature = await api.addIdCardSignaturePayout({
              documentId,
              sessionCode: hashResponse.sessionCode,
              request: { signature: fromByteArray(signature.value) }
            });
            break;
        }

        if (responseSignature) {
          if (responseSignature?.data?.status) {
            commit("SET_STATUS", responseSignature.data.status)
            setTimeout(() => {
              dispatch(
                "payments/loadPayment",
                { paymentId: router.currentRoute.params.paymentId || documentId, type: 'OUTGOING' },
                { root: true }
              );
            }, 3000);
          } else {
            commit("SET_STATUS", 'SIGNING_COMPLETED')
          }
        }
      } catch (e) {
        commit("SET_SIGN_ERROR", true);
      }
      
      switch (documentType) {
        case "loan":
          dispatch(
              "loans/loadLoanResources",
              { loanId: rootState?.loans?.loan?.id },
              { root: true }
          );
          break;
      }
      endLoading(dispatch, `signature:document:${documentId}:sign`);
    }
  },

  mutations: {
    SET_DOCUMENT_TYPE(state, { data = null } = {}) {
      state.documentType = data
    },

    SET_STATUS(state, value) {
      state.completedSessionStatus = value
    },

    SET_SIGN_DATA(state, { data = null } = {}) {
      state.signData = data;
    },

    SET_SIGN_ERROR(state, value) {
      state.signError = value;
    }
  }
};
